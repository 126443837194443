













































import { CompanyVehicle, ICompanyVehicle } from '@/shared/model/companyVehicle.model'
import { IReport } from '@/shared/model/report.model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'CompanyVehicleDetails',

  props: {
    companyVehicle: {
      type: Object,
      default: {} as ICompanyVehicle,
    },
  },
  data () {
    return {
      selectedVehicle: new CompanyVehicle(),
    }
  },
  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
  },
  watch: {},
  created () {},
  methods: {},
})
